import App from './App'

// vuex
import store from './store'
// 引入全局uview-plus
import uviewPlus from './uni_modules/uview-plus/index.js'

// i18n部分的配置
// 引入语言包，注意路径
import zhHans from '@/common/locales/zh.js';
import en from '@/common/locales/en.js';
const messages = {
	en,
	'zh-Hans': zhHans,
}
let i18nConfig = {
	locale: uni.getLocale(), // 获取已设置的语言
	messages
}
import dayjs from 'dayjs'
//import mixin from './common/mixin'

// #ifdef MP-WEIXIN
// 小程序分享封装
import maShare from '@/uni_modules/uview-plus/libs/mixin/mpShare.js'
// #endif

// #ifdef VUE3
import {
	createSSRApp
} from 'vue'
import vuexStore from '@/store/$u.mixin.js'
import { createI18n } from 'vue-i18n'
const i18n = createI18n(i18nConfig)
import {initRequest} from './util/request/index'
import {settingApp} from './util/settingApp'
import {settingLocation} from './util/location'

import pageTools from './util/page-tools/index.js'



// import {
// 	initRequest
// } from './util/request/index'
export function createApp() {
	const app = createSSRApp(App)
	app.use(dayjs)
	// #ifdef MP-WEIXIN
	app.mixin(maShare)
	// #endif
	app.mixin(vuexStore);
	// 引入请求封装
	// initRequest(app)
	app.use(store)
		.use(i18n)
		.use(uviewPlus)
	// 引入请求封装
	initRequest(app)
	settingApp(app)
	settingLocation(app)
	
	return {
		app
	}
}
// #endif
