/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-20 17:10:21
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/notify.js
 */
export default {
    // notify组件
    notify: {
        top: 0,
        type: 'primary',
        color: '#ffffff',
        bgColor: '',
        message: '',
        duration: 3000,
        fontSize: 15,
        safeAreaInsetTop: false
    }
}
