const settingLocation=(vm)=>{
	//结构化location
	const structLocation = (address)=>{
		// { "country": "中国","province": "广东省","city": "深圳市","district": "龙岗区","street": "莲塘尾一路","streetNum": "34号2栋","poiName": "大运软件小镇","cityCode": "0755"}
		let result = undefined;
		if(address &&  address.address_components){
			result = {}
			const address_components = address.address_components||[]
			const place_id = address.place_id||{}
			const geometry = address.geometry||{}
			const geometry_location = geometry.location||{}
			let countr
			address_components.map(function(item){
				const types = item.types||[]
				const long_name = item.long_name||''
				const short_name = item.short_name||''
				if(types.indexOf('country')>-1){
					result.country = long_name
				}
				if(types.indexOf('administrative_area_level_1')>-1){
					result.province = long_name
				}
				if(types.indexOf('locality')>-1){
					result.city = long_name
				}
				if(types.indexOf('sublocality_level_1')>-1){
					result.district = long_name
				}
				if(types.indexOf('route')>-1){
					result.street = long_name
				}
			})
			
		}
		return result
		
	}
	
	// 选取最优的一个地址
	const optimalAddress = (data)=>{
		let result = undefined;
		if(data && data[0] ){
			result = data[0]||undefined
			for(let i =0;i<data.length;i++){
				const address = data[i];
				const types = address.types||[]
				if(types.indexOf('route')>-1){
					result = address
					break;
				}else if(types.indexOf('street_address')>-1){
					result = address
					break;
				}
				
				
			}
		}
		return result;
	}
	
	
	//获取定位
	vm.config.globalProperties.$getLocation =  () => {
		console.log('----$getlocation',)
		uni.getLocation({
			geocode: true,
			success:  (res) => {
				console.log(res)
				const lng = res.longitude
				const lat = res.latitude
				const address = res.address
				if(!address){
					vm.config.globalProperties.$parseLocation(lng, lat).then((data)=>{
						vm.config.globalProperties.$updateLocation(lng,lat,data)
					})
				}else{
					vm.config.globalProperties.$updateLocation(lng,lat,address)
				}
				
			},
			fail:  (err) => {
				uni.$u.toast('Abnormal positioning')
				console.log(err)
			}
		});
	}
	
	//解析定位
	vm.config.globalProperties.$parseLocation = (lng,lat)=>{
		const latLng = lat+','+lng
		const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${getApp().globalData.map_gd_webser_api}&latlng=${latLng}`;
		console.log(url)
	
		return uni.$u.http.get(url,{ custom:{ return:true,ignoreLoaing:true }  }).then((res) => {
	 		// console.log('--getLocation :suc',res)
			const results = res.results||[]
			// console.log('--getLocation :results',results)
			// console.log('--getLocation :optimalAddress',optimalAddress(results))
			// console.log('--getLocation :structLocation',structLocation(optimalAddress(results)))
			return structLocation(optimalAddress(results))
		 }).catch(err=>{
			console.log('--getLocation 2err',err)
		 })
	}
	
	//更新地址信息
	vm.config.globalProperties.$updateLocation = (lng,lat,address)=>{
		console.log('--$updateLocation',lng,lat,address)
		// store.commit('updateLngLat',{ lng:lng,lat:lat,positionAddress:address })
		//响应实际
		uni.$emit('event_lng_lat',{lng:lng,lat:lat,address:address})
	}
	
	
   
   
   vm.config.globalProperties.$searchLocation = ({keyword,lng,lat})=>{
   		const latLng = lat+','+lng
		const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?radius=3000&key=${getApp().globalData.map_gd_webser_api}&location=${latLng}&keyword=${keyword}`;
   		console.log(url)
   		return uni.$u.http.get(url,{ custom:{ return:true,ignoreLoaing:true  }  }).then((res) => {
			console.log('--getLocation :suc',res)
			const results = res.results||[]
			const data = [];
			results.map(function(item){
				const geometry = item.geometry||{}
				const place_id = item.place_id||""
				const name = item.name||""
				const vicinity = item.vicinity||""
				const geometry_location = geometry.location||{}
				const lng = geometry_location.lng
				const lat = geometry_location.lat
				data.push({
					name:name,
					address:vicinity,
					lng:lng,
					lat:lat,
					place_id:place_id,
				})
			})
			return data;
			
   		}).catch(err=>{
   					  console.log('--getLocation err',err)
   		})
	}
}
export {
	settingLocation
}