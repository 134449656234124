import {
	createStore
} from 'vuex'
let lifeData = {};

try {
	// 尝试获取本地是否存在lifeData变量，第一次启动APP时是不存在的
	lifeData = uni.getStorageSync('lifeData');
} catch (e) {

}
// 需要永久存储，且下次APP启动需要取出的，在state中的变量名
let saveStateKeys = ['vuex_token', 'vuex_freight_plan', 'vuex_buy_address'];

// 保存变量到本地存储中
const saveLifeData = function(key, value) {
	// 判断变量名是否在需要存储的数组中
	if (saveStateKeys.indexOf(key) != -1) {
		// 获取本地存储的lifeData对象，将变量添加到对象中
		let tmp = uni.getStorageSync('lifeData');
		// 第一次打开APP，不存在lifeData变量，故放一个{}空对象
		tmp = tmp ? tmp : {};
		tmp[key] = value;
		// 执行这一步后，所有需要存储的变量，都挂载在本地的lifeData对象中
		uni.setStorageSync('lifeData', tmp);
	}
}
const store = createStore({
	// 为了不和页面或组件的data中的造成混淆，state中的变量前面建议加上$符号
	state: {
		vuex_platform: '',
		vuex_token: lifeData.vuex_token ? lifeData.vuex_token : '',
		//选中的地址
		vuex_buy_address: lifeData.vuex_buy_address ? lifeData.vuex_buy_address : {},
		//运费方案
		vuex_freight_plan: lifeData.vuex_freight_plan ? lifeData.vuex_freight_plan : {},
		//当前登录者用户id
		vuex_user_id: '',
		vuex_user_name: '',
		vuex_user_avatar: '',

		vuex_location_place: [], //附近的位置
		vuex_lng: '114.050371',
		vuex_lat: '22.535999',
		//定位地址
		vuex_positionAddress: {},
		//用户所在城市
		vuex_user_in_city: '',
		//用户选择的城市
		vuex_user_select_city: '',
		//显示用户定位名称
		vuex_user_in_address_name: '',
		//显示用户选择的地址名称
		vuex_user_select_address_name: '',
		//客户端id
		vuex_client_id: '',

		//购物车商品数量
		vuex_cart_num: 0,
		//上传信息
		vuex_upload_info: {},
		//选择的优惠券
		vuex_select_coupon: {},
		//选择的优惠券
		//开始时间
		vuex_order_start_time: '',
		//结束时间
		vuex_order_end_time: '',
		//选择的项目
		vuex_order_project: {},
		//选择的项目价格属性
		vuex_order_project_price: {},
		//选择的技师
		vuex_order_master: {},
		//第三方授权需要绑定的信息
		vuex_third_provider: {},
		vuex_third_user_info: {},
		vuex_video:''
	},
	mutations: {
		$uStore(state, payload) {
			// 判断是否多层级调用，state中为对象存在的情况，诸如user.info.score = 1
			let nameArr = payload.name.split('.');
			let saveKey = '';
			let len = nameArr.length;
			if (len >= 2) {
				let obj = state[nameArr[0]];
				for (let i = 1; i < len - 1; i++) {
					obj = obj[nameArr[i]];
				}
				obj[nameArr[len - 1]] = payload.value;
				saveKey = nameArr[0];
			} else {
				// 单层级变量，在state就是一个普通变量的情况
				state[payload.name] = payload.value;
				saveKey = payload.name;
			}
			// 保存变量到本地，见顶部函数定义
			saveLifeData(saveKey, state[saveKey])
		},
		//处理登录
		handleLogin(state, payload = {}) {
			//永久 缓存数据
			this.commit('$uStore', {
				name: 'vuex_token',
				value: payload.token || ''
			})
			//更新状态
			state.vuex_user_id = payload.user_id || 0
			// 管理员状态
			state.vuex_is_manager = payload.is_manager || 0
			state.vuex_user_name = payload.user_name || ''
			state.vuex_user_avatar = payload.user_avatar || ''
		},
		updateLngLat(state, {
			lng,
			lat,
			positionAddress
		}) {
			/*
			 * postionAddress结构
			 * { "country": "中国","province": "广东省","city": "深圳市","district": "龙岗区","street": "莲塘尾一路","streetNum": "34号2栋","poiName": "大运软件小镇","cityCode": "0755"}
			 */
			// console.log('--store-$updateLocation',lng,lat,positionAddress)
			state.vuex_lng = lng
			state.vuex_lat = lat
			if (positionAddress) {
				state.vuex_positionAddress = positionAddress
				state.vuex_user_in_city = positionAddress.city
				if (!state.vuex_user_in_address_name) {
					if (positionAddress.poiName) {
						state.vuex_user_in_address_name = positionAddress.poiName
					} else if (positionAddress.street) {
						state.vuex_user_in_address_name = positionAddress.street
					} else if (positionAddress.district) {
						state.vuex_user_in_address_name = positionAddress.district
					} else if (positionAddress.city) {
						state.vuex_user_in_address_name = positionAddress.city
					} else if (positionAddress.province) {
						state.vuex_user_in_address_name = positionAddress.province
					} else if (positionAddress.country) {
						state.vuex_user_in_address_name = positionAddress.country
					}
				}
				if (!state.vuex_user_select_address_name) {
					state.vuex_user_select_address_name = state.vuex_user_in_address_name
				}
			}
		},
		updateLocationAround(state, {
			pois
		}) {
			// console.log('--store-$updateLocation',pois)
			state.vuex_location_place = pois;
		},
		selectLocation(state, {
			lng,
			lat,
			name,
			address
		}) {
			state.vuex_lng = lng
			state.vuex_lat = lat
			state.vuex_user_select_address_name = name
		}

	},
	getters: {
		get_user_face(state) {
			return state.vuex_user_avatar ? state.vuex_user_avatar : '/static/images/kf.png'
		},
		get_user_show_address_name(state) {
			if (state.vuex_user_select_address_name) {
				return state.vuex_user_select_address_name
			} else {
				return 'Abnormal positioning'
			}
		},
		get_user_in_city(state) {
			if (state.vuex_user_in_city) {
				return state.vuex_user_in_city
			} else {
				return 'Abnormal positioning'
			}
		},
		quipment() {
			let systemInfo = uni.getSystemInfoSync()
			return systemInfo.screenWidth >= 768 ? true : false
		}
	},
	actions: {
		tokenFlushUserInfo({
			state,
			commit
		}) {
			console.log('-------tokenFlushUserInfo----222', state.vuex_token)
			if (state.vuex_token) {
				uni.$network("/api/user/flush", {
					user_token: state.vuex_token
				}, {
					custom: {
						ignoreLoaing: true
					},
				}).then(({
					data
				}) => {
					commit('handleLogin', data)
				})
			}

		},
		handleLogout({
			state,
			commit
		}) {
			//提交退出信息
			commit('handleLogin', {})
		},
		//购物车商品数量
		refreshCartNum({
			state
		}, payload) {
			if (payload && payload.hasOwnProperty('num')) {
				state.vuex_cart_num = payload.num
			} else {
				uni.$network("/api/goods/info", {
					type: 'cart_num'
				}, {
					custom: {
						ignoreLoaing: true
					},
				}).then(({
					data
				}) => {
					state.vuex_cart_num = data.cart_num || 0
				})
			}
		},
		clientInfo({
			state,
			commit,
			dispatch
		}, payload) {
			// #ifdef APP-PLUS
			uni.getPushClientId({
				success: (res) => {
					console.log("getPushClientId-success", res.cid);
					state.vuex_client_id = res.cid
				},
				fail(err) {
					console.log("getPushClientId-err", err)
					// dispatch('clientInfo')
				}
			})
			// #endif 
		},
		setPlatform({
			state,
			commit,
			dispatch
		}) {
			const sysInfo = uni.getSystemInfoSync();
			state.vuex_platform = sysInfo.platform || ''
		},

	}
})

export default store
