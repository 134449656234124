/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-20 16:54:47
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/checkboxGroup.js
 */
export default {
    // checkbox-group组件
    checkboxGroup: {
        name: '',
        value: () => [],
        shape: 'square',
        disabled: false,
        activeColor: '#2979ff',
        inactiveColor: '#c8c9cc',
        size: 18,
        placement: 'row',
        labelSize: 14,
        labelColor: '#303133',
        labelDisabled: false,
        iconColor: '#ffffff',
        iconSize: 12,
        iconPlacement: 'left',
        borderBottom: false
    }
}
