/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-20 16:56:42
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/collapseItem.js
 */
export default {
    // collapseItem 组件
    collapseItem: {
        title: '',
        value: '',
        label: '',
        disabled: false,
        isLink: true,
        clickable: true,
        border: true,
        align: 'left',
        name: '',
        icon: '',
        duration: 300
    }
}
