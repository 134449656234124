/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-20 17:01:13
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/swipeActionItem.js
 */
export default {
    // swipeActionItem 组件
    swipeActionItem: {
        show: false,
        name: '',
        disabled: false,
        threshold: 20,
        autoClose: true,
        options: [],
        duration: 300
    }
}
