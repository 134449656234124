export default {
	network:{
		baseURL: process.env.NODE_ENV === 'development'? 'http://tp6.mgjk.com' : 'https://www.massajoy.com',
		// http://tp6.mgjk.com
		baseURL: 'https://www.massajoy.com',
		// 第一个http://192.168.5.129
		timeout: 60000,
		custom:{
			//设置网络请求timerid
			loadingTimer: 0,
			//忽略网络请求loading
			ignoreLoaing: false,
			errShowToast: true,
			completeFnc:function(res){}
		},
		// 返回当前请求的task, options。请勿在此处修改options。非必填
		getTask: (task, options) => {
			
			// 相当于设置超时时间500ms
			// clearTimeout(getApp().globalData.loadingTimer);
			if(!options.custom.ignoreLoaing){
				options.custom.loadingTimer = setTimeout(() => {
					uni.showLoading()
					options.custom.loadingTimer = null
				}, 500)
			}
			
			 
		},
	}
	
}
