/*
 * @Author       : LQ
 * @Description  :
 * @version      : 1.0
 * @Date         : 2021-08-20 16:44:21
 * @LastAuthor   : LQ
 * @lastTime     : 2021-08-20 17:05:25
 * @FilePath     : /u-view2.0/uview-ui/libs/config/props/gap.js
 */
export default {
    // gap组件
    gap: {
        bgColor: 'transparent',
        height: 20,
        marginTop: 0,
        marginBottom: 0,
        customStyle: {}
    }
}
