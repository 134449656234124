<script>
	export default {
		globalData:{
			map_gd_webser_api:'AIzaSyAB1juDooQIIPwMAgUYLzrRPv5av0-b2EE',
			socketUrl:'',
			socketMessageCustomer: (event,content)=>{},
			//项目开发中状态
			projectOpeningState:0,
			//检查更新
			checkUpdateState:0,
			//初始化信息
			initObj:{},
		},
		onLaunch: function() {
			console.log('App Launch')
			this.$store.dispatch('tokenFlushUserInfo')
			// this.$store.dispatch('clientInfo')
			this.registerEvent()
		},
		onShow: function() {
			console.log('App Show')
			
		},
		onHide: function() {
			console.log('App Hide')
		},
		methods:{
			registerEvent(){
				console.log('--------------------registerEvent-')
				uni.$on('event_lng_lat',({lng,lat,address})=>{
					this.$store.commit('updateLngLat',{ lng:lng,lat:lat,positionAddress:address })
				})
				uni.$on('event_updateLocationAround',({pois})=>{
					this.$store.commit('updateLocationAround',{pois:pois })
				})
				
				uni.$on('loginEvent',(payload)=>{
					console.log('--------------------loginEvent-')
					this.$showModal({
						content: 'Go to sign in？',
						success: (res) => {
							if (res.confirm) {
								uni.navigateTo({
									url:'/pages/login/signln/signln?channel=pages'
								})
								
							} else if (res.cancel) {
								uni.$u.toast("Go to sign in")
								console.log('用户点击取消');
							}
							
						}
					})
				})
				
				//退出事件监听
				uni.$on('handleLogout',(payload)=>{
					this.$store.dispatch('handleLogout')
				})	
				
			
			},
		}
	}
</script>
	
<style lang="scss">
	/*每个页面公共css */
	
	/* #ifndef APP-PLUS-NVUE */
	@import "@/static/css/animation.css";
	@import "@/static/css/main.css";
	@import "@/static/css/icon.css";
	@import "uni_modules/uview-plus/index.scss";
	@import "common/demo.scss";
	/* #endif */
</style>
