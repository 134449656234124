//关闭loading动画
const requestHideLoading = (timeId) => {
	// console.log('-0-----requestHideLoading',timeId)
	uni.hideLoading()
	clearTimeout(timeId)

}

const requestInterceptors = (vm) => {
	/**
	 * 请求拦截
	 * @param {Object} http
	 */
	uni.$u.http.interceptors.request.use((config) => { // 可使用async await 做异步操作
			// 初始化请求拦截器时，会执行此方法，此时data为undefined，赋予默认{}
			config.data = config.data || {}
			// 可以在此通过vm引用vuex中的变量，具体值在vm.$store.state中
			const storeState = vm.config.globalProperties.$store.state
			// console.log('？？？？？？？？？？？',vm.config.globalProperties.$store.state,vm.$store);
			if (config.url.indexOf("http") === 0) { //第三方

			} else {

				config.header['user-token'] = storeState.vuex_token;
				config.header['platform-info'] = uni.$u.os() + ';' + (storeState.vuex_share_uid || 0) + ';' +
					storeState.vuex_lng + '-' + storeState.vuex_lat;
			}
			return config
		}, (config) => // 可使用async await 做异步操作
		Promise.reject(config))
}
const responseInterceptors = (vm) => {
	/**
	 * 响应拦截
	 * @param {Object} http 
	 */
	uni.$u.http.interceptors.response.use((response) => {
		const custom = response.config?.custom
		//关闭loading动画
		if(!custom.ignoreLoaing){
			requestHideLoading(custom.loadingTimer)
		}
		if(custom.return){ //zhi jie fan hui jie guo 
			return response.data;
		}
		
		//请求完成
		custom?.completeFnc(response)
		
		if (response.statusCode!=200){
			uni.$u.toast("request err:"+200)
		}
		
		const {code,msg,data} = response.data || {}
		if(custom.showResData){
			console.log('--http-response',data,response)	
		}
		if(code===-1){
			// uni.navigateTo({ url:"/pages/index/index" })
			uni.$u.toast(msg);
			uni.$emit('loginEvent')
			return Promise.reject(response.data)
		}else if(code==-2){ //账号被禁用
			uni.$u.toast(msg);
			vm.$store.dispatch('handleLogout')
			return 
		}else if(code!==1){
			if(custom.errShowToast){
				uni.$u.toast(msg);
			}
			return Promise.reject(response.data)
		}else{
			return response.data;
		}
	}, (response) => {
		const custom = response.config?.custom
		//关闭loading动画
		requestHideLoading(custom.loadingTimer)
		
		//请求完成
		custom?.completeFnc(response)
		
		if(response.errMsg==="request:fail timeout"){
			uni.$u.toast("network timeout")
		}
		return Promise.reject(response)
	})
}


export {
	requestInterceptors,
	responseInterceptors
}
