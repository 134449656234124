//  初始化请求配置
const settingApp=(vm)=>{
	 //初始化模板变量
	vm.config.globalProperties.$tempDataInit = function(temp,obj,item){
		for (var key in temp) {
			var value_type = typeof temp[key]
			var value = item[key]===undefined || item[key] === null ? temp[key] : item[key];

			var default_value = undefined
			if( Array.isArray(temp[key]) ) {
				default_value = []
			}else if(value_type instanceof Object){
				default_value = {}
			}else if(value_type === 'string' ) {
				default_value = ""
				value = value + ""
			}else if(value===0){
				default_value = 0
			}
			obj[key] = value||default_value
		}
	}
	 //添加网络请求插件
	const $network = function(api,params={},options={},method="post"){
		return new Promise((resolve,reject)=>{
			uni.$u.http[method](api, params, !(options instanceof Function) ? options : {
				custom:{
					completeFnc:(res)=>{
						options(res)
					}
				}
			}).then(res => {
				resolve(res)
			}).catch(err=>{
				reject(err)
			})
		})
	}
	uni.$network = $network
	vm.config.globalProperties.$network =$network
	
	
	//页面跳转
	vm.config.globalProperties.$multiRedirect=function(url,loginState){
		console.log('>>>>>>>>>>>>$multiRedirect',url)
		if ( loginState === true ) {
			if(!this.vuex_user_id){
				uni.$emit('loginEvent')
				return
			}
		}
	    if(url===-1){
			uni.navigateBack()
		}else if(url && url.length>0){
			console.log('+++++',url.indexOf('http'))
	        if(url.indexOf('http')===0){
				// #ifdef H5 
					location.href=url
				// #endif
				// #ifdef MP-WEIXIN
					 uni.navigateTo({ 
						 url:'/pages/web/web?url='+encodeURIComponent(url)
					 })
				// #endif
				// #ifdef APP-PLUS
				plus.runtime.openURL(url, function(res) {  
					console.log(res);  
				});
				// #endif
			}else if(url.indexOf('&switch')>-1){
	            uni.switchTab({url})
	        }else {
	            uni.navigateTo({ url})
	        }
	    }
	}
	//拨打电话
	vm.config.globalProperties.$makePhoneCall=function(tel){
		console.log("makePhoneCall",tel)
		uni.showModal({
			content: 'Making phone calls?',
			cancelText:'Cancel',
			confirmText:'Confirm',
			success: function (res) {
				if (res.confirm) {
					uni.makePhoneCall({
						phoneNumber:tel+'',
						success() {
							console.log('success')
						},
						fail() {
							console.log('fail')
						}
					})
				} else if (res.cancel) {
					console.log('用户点击取消');
				}
			}
		});
		
	}
	
	//showModal
	vm.config.globalProperties.$showModal = function(json){
		return uni.showModal(Object.assign({
			cancelText:'Cancel',
			confirmText:'Confirm',
			success: (res) => {
				
				
			}
		},json))
	}
	//显示图片
	vm.config.globalProperties.$previewImage = function(list){
		uni.previewImage({
			urls: list,
			
		});
	}
	
	//json
	vm.config.globalProperties.$jsonToQuery = function(json){
		let query = ''
		Object.keys(json).map(function(key){
			query += key + '='+json[key]+'&'
		})
		console.log(query)
		return query.length > 0 ? query.substring(0,query.length-1): query;
	}
	//验证邮箱
	vm.config.globalProperties.$validateEmail = function(str){
		const reg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
		return reg.test(str);
	}
	//验证邮箱
	vm.config.globalProperties.$openLocation = function(lat,lng){
		uni.openLocation({
			latitude: parseFloat(lat),
			longitude: parseFloat(lng),
			success: function (res) {
				console.log('success',res);
			},
			fail:function(err){
				console.log('err',err);
			}
		});
	}
	
	//生成本地图片
	vm.config.globalProperties.$videoToImage_local = function(url){
		
		return new Promise(function (resolve, reject) {
			let dataURL = '';
				let video = document.createElement("video");
				video.setAttribute('crossOrigin', 'anonymous');//处理跨域
				video.setAttribute('src', url);
				video.setAttribute('width', 400);
				video.setAttribute('height', 240);
				video.setAttribute('preload', 'auto');
				video.addEventListener('loadeddata', function () {
					let canvas = document.createElement("canvas"),
						width = video.width, //canvas的尺寸和图片一样
						height = video.height;
					canvas.width = width;
					canvas.height = height;
					canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
					dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
					resolve(dataURL);
				});
			
		})
		
		
	}
	
	
	
	/*
	获取缓存数据-有就读取,没有 就网络请求
	obj-对象格式  {"数据类型":"缓存的键名"}
		获取商品分类 可使用 {goods_cate:goodsCateList}
	*/
	vm.config.globalProperties.$getCacheData = (function(){
		const cacheConfing = {}
		return function(obj,fnc,refresh){
			const forceRefresh = refresh===true
			if(!(fnc instanceof Function) || !(obj instanceof Object)){
				return 
			}
			let query = {}
			let results = {};
			if(forceRefresh){
				query = obj
			}else{
				Object.keys(obj).map(function(key){
					const valueKey = obj[key]
					if(!cacheConfing[valueKey]){
						query[key] = valueKey
					}else{
						//绑定缓存数据
						results[valueKey] = cacheConfing[valueKey]
					}
				})
				
			}
			
			if(!Object.keys(query).length){
				//返回数据
				fnc(results)
			}else{
				$network('/api/index/groupData',query).then((res)=>{
					const data = res.data||{}
					
					Object.keys(data).map(function(key){
						const value = data[key];
						results[key] = value;
						cacheConfing[key] = value;
					})
					//返回数据
					fnc(results)
				})
			}
			
			
			
		}
	})()
	
	
}
export {
	settingApp
}